import React from "react";
import { graphql } from "gatsby";
import ImageGallery from "react-image-gallery";
import SEO from "../components/seo";
import Layout from "../components/layout";
import BottomNav from "../components/bottom-nav";

import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";

export default ({ data }) => {
  const images = [
    {
      original: data.palacePhoto.publicURL,
    },
    {
      original: data.bluejayPhoto.publicURL,
    },
    {
      original: data.chipmunkPhoto.publicURL,
    },
    {
      original: data.lighthousePhoto.publicURL,
    },
    {
      original: data.cardinalPhoto.publicURL,
    },
    {
      original: data.hummingbirdPhoto.publicURL,
    },
  ];

  return (
    <Layout indexPage={false}>
      <SEO title="Photography" keywords={["photography"]} />
      <div className="flex flex-col justify-between items-center h-full">
        <div />
        <div className="md:w-2/3 md:my-32">
          <ImageGallery
            items={images}
            showThumbnails={false}
            showPlayButton={false}
          />
        </div>
        <div className="mx-auto">
          <BottomNav />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bluejayPhoto: file(relativePath: { eq: "photo_bluejay.jpg" }) {
      publicURL
    }

    cardinalPhoto: file(relativePath: { eq: "photo_cardinal.jpg" }) {
      publicURL
    }

    chipmunkPhoto: file(relativePath: { eq: "photo_chipmunk.jpg" }) {
      publicURL
    }

    hummingbirdPhoto: file(relativePath: { eq: "photo_hummingbird.jpg" }) {
      publicURL
    }

    lighthousePhoto: file(relativePath: { eq: "photo_lighthouse.jpg" }) {
      publicURL
    }

    palacePhoto: file(relativePath: { eq: "photo_palace.jpg" }) {
      publicURL
    }
  }
`;
